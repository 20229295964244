<template>
	<div class="gfxx-con">
		<!-- <divBox1 :title="'光伏信息'"></divBox1> -->
		<div class="main-box-guangfuxinxi mt-50">
			<div v-for="(item, i) in target" :key="'target' + i" class="main-box-cell">
				<div v-if="i == 0" class="top">{{ nowType() == '日' ? item.label : nowType() + '平均功率' }}</div>
				<div v-else class="top">{{ nowType() + item.label }}</div>
				<img class="itemImg" :src="item.icon" alt="">
				<div class="bottom">
					<div class="bottom-top DS-Digital-Bold">{{ item.number }}</div>
					<div class="bottom-bottom">{{ item.unit }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		dataInterface
	} from '@/api/dataInterfaceApi';
	export default {
		components: {},
		data() {
			// let mIcon = require('@/assets/images/exposition/shishigonglvdizuo1.png')
			let mIcon = require('@/assets/images/exposition/chd-bg-dummy.png')
			return {
				photovoltaic: {},
				now: '日',
				target: [{
						icon: mIcon,
						label: '实时功率',
						number: '',
						unit: 'kW'
					},
					{
						icon: mIcon,
						label: '发电量',
						number: '',
						unit: 'kWh'
					},
					{
						icon: mIcon,
						label: '收益',
						number: '',
						unit: '元'
					}
				]
			}
		},
		destroyed() {
			// this.$bus.$off('onfangdiangonglv')
		},
		mounted() {
			this.$bus.$on('onfangdiangonglv', () => {
				this.getphotovoltaic()
			})
			this.getphotovoltaic()
		},
		methods: {
			nowType() {
				return localStorage.getItem('typeTime') || '日'
			},
			getphotovoltaic() {
				const now = new Date();
				const year = now.getFullYear();
				const month = (now.getMonth() + 1).toString().padStart(2, "0");
				const day = now.getDate().toString().padStart(2, "0");
				const timer = `${year}-${month}-${day}`
				let params = {
					"enCode": "snec-left-guangfuxinxi",
					"@harbour_id": localStorage.getItem("harbour_id") || '76df7579ccc24d2693f6488c38a3a0b0',
					"@dim_date": localStorage.getItem("changeDataNuber") || timer,
				}
				dataInterface.dataFaceApi(params).then((res) => {
					console.log(res, '光伏信息=============')
					let data = res[0] || {}
					this.target[0].number = data.avg_real_time_output_power ? Math.round(data.avg_real_time_output_power) : 0
					this.target[1].number = data.daily_generating_capacity ? Math.round(data.daily_generating_capacity) : 0
					this.target[2].number = data.daily_earnings ? Math.round(data.daily_earnings) : 0
				}).catch((err) => {
					console.log(err);
				});
			}
		}
	}
</script>

<style lang="scss" scope>
	@font-face {
		font-family: 'DS-Digital-Bold';
		src: url('~@/assets/font/DS-Digital-Bold.ttf');
	}
	.DS-Digital-Bold {
		font-family: "DS-Digital-Bold";
	}
	.gfxx-con {
		.main-box-guangfuxinxi {
			font-size: 24px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			// margin-top: 75px;

			// @keyframes breath_light {
			// 	0% {
			// 		filter: brightness(100%);
			// 	}

			// 	50% {
			// 		filter: brightness(180%);
			// 	}

			// 	100% {
			// 		filter: brightness(100%);
			// 	}
			// }

			.main-box-cell {
			// 	background-image: url("~@/assets/images/exposition/shishigonglvdizuo.png");
			// background-size: 100% 100%;
				// animation: breath_light 3s ease infinite;
				margin-bottom: 6px;
				display: flex;
				width: 190px;
				height: 260px;
				position: relative;
				text-align: center;
				font-size: 30px;
				img {
					width: 177px;
					display: block;
					height: 115px;
					margin: 120px auto 0;
				}

				.top {
					position: absolute;
					top: 0px;
					width: 100%;
					text-align: center;
					background-image: url("~@/assets/images/exposition/chd-label.png");
					background-size: 100% 100%;
				}

				.bottom {
					width: 100%;
					font-size: 28px;
					text-align: center;
					position: absolute;
					top: 90px;

					.bottom-top {
						font-size: 46px;
						height: 30px;
						line-height: 20px;
						color: #00FDF4;
					}
				}
			}
		}
	}
</style>