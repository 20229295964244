<template>
		<!-- <divBox2 :title="'累计收益'"></divBox2> -->
		<div style="width:1140px;height:360px;margin-top: 20px;">
			<MyEcharts :visible="showcdata" :id="'options24h33ours332332e4333warn1122'" :options="cdata" />
		</div>
</template>

<script>
	import MyEcharts from '@/views/components/charts/echarts.vue'
	import * as echarts from 'echarts'
	import {
		dataInterface
	} from '@/api/dataInterfaceApi';
	export default {
		components: {
			MyEcharts
		},
		data() {
			return {
				showcdata: false,
				// title:"车辆新增及活跃趋势",
				cdata: {
					title: {
						text: "元",
						left:40,
					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: []
					},
					yAxis: {
						// name: '元  ',
						type: 'value',
						splitLine: false
					},
					series: [{
							name: '光伏收益',
							type: 'line',
							smooth: true,
							data: [],
							barWidth: 2,
							tooltip: {
								valueFormatter: function(value) {
									return value + ' 元';
								}
							},
							lineStyle: {
								color: '#6dfbfe'
							},
							areaStyle: {
								opacity: 0.8,
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 0,
										color: '#6dfbfe'
									},
									{
										offset: 1,
										color: '#183b3d'
									}
								])
							},
						}, {
							name: '储能收益',
							type: 'line',
							smooth: true,
							barWidth: 2,
							tooltip: {
								valueFormatter: function(value) {
									return value + ' 元';
								}
							},
							lineStyle: {
								color: '#f2c585'
							},
							areaStyle: {
								opacity: 0.8,
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 0,
										color: '#f2c585'
									},
									{
										offset: 1,
										color: '#51432e'
									}
								])
							},
						}, {
							name: '充电收益',
							type: 'line',
							smooth: true,
							data: [],
							barWidth: 2,
							tooltip: {
								valueFormatter: function(value) {
									return value + ' 元';
								}
							},
							lineStyle: {
								color: '#D4691C'
							},
							areaStyle: {
								opacity: 0.8,
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 0,
										color: '#D4691C'
									},
									{
										offset: 1,
										color: '#492913'

									}
								])
							},
						},
						{
							name: '换电收益',
							type: 'line',
							smooth: true,
							data: [],
							barWidth: 2,
							tooltip: {
								valueFormatter: function(value) {
									return value + ' 元';
								}
							},
							lineStyle: {
								color: '#659bf1'
							},
							areaStyle: {
								opacity: 0.8,
								color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
										offset: 0,
										color: '#659bf1'
									},
									{
										offset: 1,
										color: '#1c2d46'
									}
								])
							},
						},
					]
				}
			};
		},
		destroyed() {
			// this.$bus.$off('onfangdiangonglv')
		},
		mounted() {
			this.$bus.$on('onfangdiangonglv', () => {
				this.getleijishouyi()
			})
			this.getleijishouyi()
		},
		methods: {
			//获取累计收益
			getleijishouyi() {
				const now = new Date();
				const year = now.getFullYear();
				const month = (now.getMonth() + 1).toString().padStart(2, "0");
				const day = now.getDate().toString().padStart(2, "0");
				const timer = `${year}-${month}-${day}`
				let params = {
					"enCode": "snec-right-leijishoyi",
					"@dim_date": localStorage.getItem("changeDataNuber") || timer,
					"@harbour_id": localStorage.getItem("harbour_id") || '76df7579ccc24d2693f6488c38a3a0b0'
				}
				this.showcdata = false
				dataInterface.dataFaceApi(params).then((res) => {
					if (res) {
						let data = []
						let data1 = []
						let data2 = []
						let data3 = []
						let xAxis = []
						res.forEach(item => {
							xAxis.push(item.dim_date)
							data.push(item.charge_earnings)
							data1.push(item.exchange_earnings)
							data2.push(item.gf_earnings)
							data3.push(item.store_earnings)
						})
						this.cdata.xAxis.data = xAxis
						this.cdata.series[0].data = data2
						this.cdata.series[1].data = data3
						this.cdata.series[2].data = data
						this.cdata.series[3].data = data1
						this.showcdata = true
					}
				}).catch((err) => {
					console.log(err);
				});
			}
		}

	}
</script>

<style lang="scss" scope>
	.main-box-diangganggaikuang {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.main-box-cell {
			margin-bottom: 6px;
			display: flex;
			width: 200px;
			height: 100px;
			padding: 0 10px;

			.main-box-left {
				img {
					width: 80px;
					height: 80px;
				}
			}

			.main-box-right {
				padding-left: 15px;

				.top {
					height: 50px;
					line-height: 70px;
				}

				.bottom {
					height: 50px;
					font-size: 22px;
					line-height: 30px;
				}
			}
		}
	}
</style>