<template>
  <div>
    <!-- <divBox2 :title="'总电量及电价'"></divBox2> -->
    <div style="width: 540px; height: 360px; margin-top: 20px">
      <MyEcharts
        :visible="showcdata"
        :id="'zongdianliangjidianjia'"
        :options="cdata"
      />
    </div>
  </div>
</template>

<script>
import MyEcharts from "@/views/components/charts/echarts.vue";
import * as echarts from "echarts";
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  components: {
    MyEcharts,
  },
  data() {
    return {
      showcdata: false,
      // title:"车辆新增及活跃趋势",
      cdata: {
        title: [
          {
            text: "度",
            top: 30,
            left: 20,
            textStyle: {
              fontSize: 18, // 设置标题字体的大小
            },
          },
          {
            text: "元/度",
            right: 20,
            top: 30,
            textStyle: {
              fontSize: 18, // 设置副标题字体的大小
            },
          },
        ],
        color: ["#11DDE1", "#FFD15C"],
        tooltip: {
          trigger: "axis",
          show: true,
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        grid: {
          top: "30%",
        },
        legend: {
          right: "16%",
          icon: "rect",
          itemWidth: 12,
          itemHeight: 8,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [],
        },
        yAxis: [
          {
            // name: "度  ",
            type: "value",
            splitLine: false,
          },
          {
            // name: "元/度",
            type: "value",
            splitLine: false,
            nameLocation: "end",
            nameTextStyle: {
              fontSize: 52,
              padding: [15, 0, 140, 0, 0], //间距分别是 上 右 下 左
            },
          },
        ],
        series: [
          {
            name: "电量",
            type: "bar",
            smooth: true,
            tooltip: {
              valueFormatter: function (value) {
                return value + " kWh";
              },
            },
            data: [],
            lineStyle: {
              width: 2,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#102e2f",
                },
                {
                  offset: 1,
                  color: "#102e2f",
                },
              ]),
            },
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  1,
                  0,
                  0,
                  [
                    {
                      offset: 0,
                      color: "#0DB9E5", // 0% 处的颜色
                    },
                    {
                      offset: 0.6,
                      color: "#0C809D", // 60% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#0B4C5D", // 100% 处的颜色
                    },
                  ],
                  false
                ),
              },
            },
          },
          {
            name: "电价",
            type: "line",
            smooth: true,
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value + " 元/度";
              },
            },
            lineStyle: {
              color: "#ffd15d",
            },
          },
        ],
      },
    };
  },
  destroyed() {
    // this.$bus.$off('onfangdiangonglv')
  },
  mounted() {
    this.$bus.$on("onfangdiangonglv", () => {
      this.getZongdianliangjidianjia();
    });
    this.getZongdianliangjidianjia();
  },
  methods: {
    //获取总表电量及电价
    getZongdianliangjidianjia() {
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, "0");
      const day = now.getDate().toString().padStart(2, "0");
      const timer = `${year}-${month}-${day}`;
      let params = {
        enCode: "snec-right-zongbiaodianliangjidianjia",
        "@dim_date": localStorage.getItem("changeDataNuber") || timer,
        "@harbour_id":
          localStorage.getItem("harbour_id") ||
          "76df7579ccc24d2693f6488c38a3a0b0",
      };
      this.showcdata = false;
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          console.log(res, "99999944449电量电价");
          if (res) {
            let data = [];
            let data1 = [];
            let xAxis = [];
            res.forEach((item) => {
              xAxis.push(item.dim_date);
              data.push(item.sum_total_meter_elect);
              data1.push(item.elect_price);
            });
            this.cdata.xAxis.data = xAxis;
            this.cdata.series[0].data = data;
            this.cdata.series[1].data = data1;
            this.showcdata = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scope>
.main-box-diangganggaikuang {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .main-box-cell {
    margin-bottom: 6px;
    display: flex;
    width: 200px;
    height: 100px;
    padding: 0 10px;

    .main-box-left {
      img {
        width: 80px;
        height: 80px;
      }
    }

    .main-box-right {
      padding-left: 15px;

      .top {
        height: 50px;
        line-height: 70px;
      }

      .bottom {
        height: 50px;
        font-size: 22px;
        line-height: 30px;
      }
    }
  }
}
</style>