<template>
  <div>
    <!-- <divBox2 :title="'发电趋势'"></divBox2> -->
    <div style="width: 540px; height: 360px; margin-top: 20px">
      <MyEcharts
        :visible="showcdata"
        :id="'options24hour4342swarn1122'"
        :options="cdata"
      />
    </div>
  </div>
</template>

<script>
import MyEcharts from "@/views/components/charts/echarts.vue";
import * as echarts from "echarts";
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  components: {
    MyEcharts,
  },
  data() {
    return {
      showcdata: false,
      // title:"车辆新增及活跃趋势",
      cdata: {
        title: [
          {
            text: "kWh",
            top: 30,
			left: 20,
            textStyle: {
              fontSize: 18, // 设置标题字体的大小
            },
          },
          {
            text: "¥",
            right: 20,
            top: 30,
            textStyle: {
              fontSize: 18, // 设置副标题字体的大小
            },
          },
        ],
        // color: ['#0A9ABE','#FFD15C','#54CBCD','#FFD15C'],
        tooltip: {
          trigger: "axis",
          show: true,
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
              // data: ['发电量', '收益', '周平均发电量', '周平均收益'],
            },
          },
        },
        grid: {
          top: "30%",
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: [],
        },
        yAxis: [
          {
            // name: "kWh",
            type: "value",
            splitLine: true,
          },
          {
            // name: "¥",
            type: "value",
            splitLine: true,
            itemStyle: {
              textAlign: "center",
            },
          },
        ],
        series: [
          {
            name: "发电量",
            type: "bar",
            smooth: true,
            data: [],
            barWidth: 5,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  1,
                  0,
                  0,
                  [
                    {
                      offset: 0,
                      color: "#0DB9E5", // 0% 处的颜色
                    },
                    {
                      offset: 0.6,
                      color: "#0C809D", // 60% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#0B4C5D", // 100% 处的颜色
                    },
                  ],
                  false
                ),
              },
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + " kWh";
              },
            },
          },
          {
            name: "收益",
            type: "line",
            smooth: true,
            data: [],
            lineStyle: {
              color: "#ffd15d",
            },
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value + " 元";
              },
            },
          },
          {
            name: "周平均发电量",
            type: "bar",
            smooth: true,
            barWidth: 5,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  1,
                  0,
                  0,
                  [
                    {
                      offset: 0,
                      color: "#15F5C9", // 0% 处的颜色
                    },
                    {
                      offset: 0.6,
                      color: "#1E927C", // 60% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#1B3633", // 100% 处的颜色
                    },
                  ],
                  false
                ),
              },
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + " kWh";
              },
            },
          },
          {
            name: "周平均收益",
            type: "line",
            smooth: true,
            data: [],
            lineStyle: {
              color: "#ffad7a",
            },
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value + " 元";
              },
            },
          },
        ],
      },
    };
  },
  destroyed() {},
  mounted() {
    this.$bus.$on("onfangdiangonglv", () => {
      this.getBattery24hours();
    });
    this.getBattery24hours();
  },
  methods: {
    //获取24小时电次数分布
    getBattery24hours() {
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, "0");
      const day = now.getDate().toString().padStart(2, "0");
      const timer = `${year}-${month}-${day}`;
      let params = {
        enCode: "snec-left-fadianqushi",
        "@dim_date": localStorage.getItem("changeDataNuber") || timer,
        "@harbour_id":
          localStorage.getItem("harbour_id") ||
          "76df7579ccc24d2693f6488c38a3a0b0",
      };
      this.showcdata = false;
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          console.log(res, "发电趋势");
          if (res) {
            let data = [];
            let data2 = [];
            let data3 = [];
            let data4 = [];
            let xAxis = [];
            res.forEach((item) => {
              xAxis.push(item.dim_date);
              data.push(item.sum_output_elect);
              data2.push(item.sum_earnings);
              data3.push(item.weekly_average_output_elect);
              data4.push(item.weekly_average_output_elect);
            });
            this.cdata.xAxis.data = xAxis;
            this.cdata.series[0].data = data;
            this.cdata.series[1].data = data2;
            this.cdata.series[2].data = data3;
            this.cdata.series[3].data = data4;
            this.showcdata = true;
            console.log("this.cdataOptions", this.cdataOptions);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scope>
.main-box-diangganggaikuang {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .main-box-cell {
    margin-bottom: 6px;
    display: flex;
    width: 200px;
    height: 100px;
    padding: 0 10px;

    .main-box-left {
      img {
        width: 80px;
        height: 80px;
      }
    }

    .main-box-right {
      padding-left: 15px;

      .top {
        height: 50px;
        line-height: 70px;
      }

      .bottom {
        height: 50px;
        font-size: 22px;
        line-height: 30px;
      }
    }
  }
}
</style>