<template>
  <div>
    <div class="main-box-chunengxinxinew">
      <div class="main-box-right">
        <div class="main-box-right-cell">
          <div class="main-box-right-cell-left">
            <img src="@/assets/images/exposition/imgp1.png" alt="" />
          </div>
          <div class="main-box-right-cell-right">
            <div>
              <!-- {{ storedEnergy.daily_earnings | Mathround  }}  -->
              <animate-number
                from="0"
                class="top DS-Digital-Bold"
                :to="storedEnergy.daily_earnings | Mathround"
                :key="storedEnergy.daily_earnings | Mathround"
                duration="2000"
              ></animate-number>
              <span class="unid">元</span>
            </div>
            <div class="bottom">{{ now }}累计收益</div>
          </div>
        </div>
        <div class="main-box-right-cell">
          <div class="main-box-right-cell-left">
            <img src="@/assets/images/exposition/imgp2.png" alt="" />
          </div>
          <div class="main-box-right-cell-right">
            <div>
              <!-- {{ storedEnergy.daily_efficiency | Mathround }} -->
              <animate-number
                from="0"
                class="top DS-Digital-Bold"
                :to="storedEnergy.daily_efficiency | Mathround"
                :key="storedEnergy.daily_efficiency | Mathround"
                duration="2000"
              ></animate-number>
              <span style="font-family: none" class="unid">%</span>
            </div>
            <div class="bottom">{{ now }}充放电效率</div>
          </div>
        </div>
        <div class="main-box-right-cell">
          <div class="main-box-right-cell-left">
            <img src="@/assets/images/exposition/imgp3.png" alt="" />
          </div>
          <div class="main-box-right-cell-right">
            <div>
              <!-- {{ storedEnergy.input_elect | Mathround}} -->
              <animate-number
                from="0"
                class="top DS-Digital-Bold"
                :to="storedEnergy.input_elect | Mathround"
                :key="storedEnergy.input_elect | Mathround"
                duration="2000"
              ></animate-number>
              <span style="font-family: none" class="unid">kWh</span>
            </div>
            <div class="bottom">{{ now }}充电量</div>
          </div>
        </div>
        <div class="main-box-right-cell">
          <div class="main-box-right-cell-left">
            <img src="@/assets/images/exposition/imgp3.png" alt="" />
          </div>
          <div class="main-box-right-cell-right">
            <div>
              <!-- {{ storedEnergy.output_elect | Mathround}} -->
              <animate-number
                from="0"
                class="top DS-Digital-Bold"
                :to="storedEnergy.output_elect | Mathround"
                :key="storedEnergy.output_elect | Mathround"
                duration="2000"
              ></animate-number>
              <span style="font-family: none" class="unid">kWh</span>
            </div>
            <div class="bottom">{{ now }}放电量</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  components: {},
  data() {
    return {
      storedEnergy: {},
      now: "日",
      channel: localStorage.getItem("newBigScreen::channel"),
    };
  },

  destroyed() {
    // this.$bus.$off('onfangdiangonglv')
  },
  filters: {
    Mathround(value) {
      if (value) {
        return Math.round(value);
      } else {
        return 0;
      }
    },
  },
  mounted() {
    this.$bus.$on("onfangdiangonglv", () => {
      this.getstoredEnergy();
    });
    this.getstoredEnergy();
  },
  methods: {
    getstoredEnergy() {
      if (!localStorage.getItem("typeTime")) {
        console.log(localStorage.getItem("typeTime"), "111");
        this.now = "日";
      } else {
        console.log(localStorage.getItem("typeTime"), "222");
        this.now = localStorage.getItem("typeTime");
      }
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, "0");
      const day = now.getDate().toString().padStart(2, "0");
      const timer = `${year}-${month}-${day}`;

      let params = {
        enCode: "snec-left-chunengxinxi",
        "@dim_date": localStorage.getItem("changeDataNuber") || timer,
        "@harbour_id":
          localStorage.getItem("harbour_id") ||
          "76df7579ccc24d2693f6488c38a3a0b0",
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          console.log(res, "储能信息");
          this.storedEnergy = res[0] || {};
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scope>
@font-face {
  font-family: "DS-Digital-Bold";
  src: url("~@/assets/font/DS-Digital-Bold.ttf");
}

.DS-Digital-Bold {
  font-family: "DS-Digital-Bold";
}

.main-box-chunengxinxinew {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 18px;

  .main-box-left {
    width: 140px;

    .img {
      width: 140px;
      height: 140px;
      position: relative;
      margin-top: 20px;

      &.beijing {
        height: 120px;
      }
      .number {
        font-size: 26px;
        position: absolute;
        width: 140px;
        height: 30px;
        top: 50%;
        transform: translate(0, -50%);
        text-align: center;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .bottom {
      text-align: center;
      height: 60px;
      line-height: 80px;
      font-size: 20px;
    }
  }

  .main-box-right {
    flex: 1;
    padding: 20px 0;
    padding-left: 10px;
    display: flex;
    flex-wrap: wrap;

    .main-box-right-cell {
      width: 50%;
      height: 140px;
      display: flex;
      margin-top: 20px;

      .main-box-right-cell-left {
        width: 100px;
      }

      .main-box-right-cell-right {
        width: 400px;
        padding-left: 10px;

        div {
          width: 100%;
          .top {
            width: 100px;
            height: 30px;
            padding: 10px 0px;
            line-height: 10px;
            font-size: 54px;
            color: #00FDF4;
          }
          .unid {
            width: 30px;
            font-size: 25px;
            margin-left: 4px;
            color: #00FDF4;
          }
        }

        .bottom {
          height: 30px;
          padding: 10px 0px;
          line-height: 60px;
          font-size: 24px;
        }
      }
    }
  }
}
</style>