<template>
	<div>
		<div class="line-box-duonenghubu"></div>
		<div class="main-box-duonenghubu mt-100">
			<div class="img-rotate-box">
				<img class="infogif" src="~@/assets/images/exposition/duonenghubu.png" alt="">
				<img class="info" src="~@/assets/images/exposition/duonenghubu-1.png" alt="">
                <!-- <video class="infogif" muted autoplay loop :src="require('@/assets/images/exposition/duonenghubu.mp4')"></video> -->
				<div class="infobox infobox1">
					<div class="top DS-Digital-Bold">
						{{datainfos.charge_discharge_elect | Mathround}} <span class="uuid">kWh</span> </div>
					<div class="bottom">光伏发电</div>
				</div>
				<div class="infobox infobox2">
					<div class="top DS-Digital-Bold">{{datainfos.charge_elect | Mathround}} <span class="uuid">kWh</span> </div>
					<div class="bottom">充电桩</div>
				</div>
				<div class="infobox infobox3">
					<div class="top DS-Digital-Bold">{{datainfos.grid_transmission | Mathround}} <span class="uuid">kWh</span> 
					</div>
					<div class="bottom">电网输电</div>
				</div>
				<div class="infobox infobox4">
					<div class="top DS-Digital-Bold">{{datainfos.exchange_elect | Mathround}} <span class="uuid">kWh</span> </div>
					<div class="bottom">换电站</div>
				</div>
				<div class="infobox infobox5">
					<div class="top DS-Digital-Bold">
						{{datainfos.store_discharge_elect | Mathround}} <span class="uuid">kWh</span> </div>
					<div class="bottom">储能放电</div>
				</div>
				<div class="infobox infobox6">
					<div class="top DS-Digital-Bold">
						{{datainfos.store_recharge_elect | Mathround}} <span class="uuid">kWh</span> </div>
					<div class="bottom">储能充电</div>
				</div>
				<div class="name flex flex-direction">
					<span>多能</span>
					<span>互补</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		dataInterface
	} from '@/api/dataInterfaceApi'
	export default {
		components: {},
		data() {
			return {
				datainfos: {

				}
			}
		},
		destroyed() {
			// this.$bus.$off('onfangdiangonglv')
		},
		filters: {
			Mathround(value) {
				if (value) {
					return Math.round(value)
				} else {
					return '-'
				}
			}
		},
		mounted() {
			this.$bus.$on('onfangdiangonglv', () => {
				this.getduonenghubu()
			})
			this.getduonenghubu()
		},
		methods: {
			//获取24小时电次数分布
			getduonenghubu() {
				const now = new Date();
				const year = now.getFullYear();
				const month = (now.getMonth() + 1).toString().padStart(2, "0");
				const day = now.getDate().toString().padStart(2, "0");
				const timer = `${year}-${month}-${day}`
				let params = {
					"enCode": "snec-right-duonenghubu",
					"@dim_date": localStorage.getItem("changeDataNuber") || timer,
					"@harbour_id": localStorage.getItem("harbour_id") || '76df7579ccc24d2693f6488c38a3a0b0'
				}
				dataInterface.dataFaceApi(params).then((res) => {
					console.log(res, '00000000')
					if (res) {
						this.datainfos = res[0] || {}
					}
				}).catch((err) => {
					console.log(err);
				});
			}
		}
	}
</script>

<style lang="scss" scope>
@import "@/assets/scss/comm.scss";
	.line-box-duonenghubu {
		// height: 280px;
	}

	.main-box-duonenghubu {
		font-size: 30px;
		width: 100%;
		position: relative;
		img {
			width: 100%;
			// height: 100%;
		}

		.info {
			width: 100%;
			position: absolute;
			left: 0;
			height: 845px;
			top: 130px;
		}
		.infogif {
			width: 880px;
			display: block;
			margin: auto;
		}

		.infobox {
			text-align: center;
			position: absolute;
			width: 350px;
			font-size: 38px;
			.top {
				font-size: 58px;
				height: 192px;
				line-height: 35px;
				color: #00FDF4;
			}

			.bottom {
				height: 50px;
				line-height: 50px;
				padding-left: 50px;
			}
		}

		.name {
			width: 150px;
			height: 100px;
			line-height: 75px;
			position: absolute;
			left: 650px;
			top: 475px;
			font-size: 70px;
			margin: 0;
			padding: 0;
			@include ffamily-HT;
		}

		.infobox1 {
			left: 208px;
			top: 117px;
			.bottom{
				margin-right: 65px;
			}
		}

		.infobox2 {
			right: 178px;
			top: 117px;
			.bottom{
				// margin-left: 65px;
				padding: 0;
			}
		}

		.infobox3 {
			left: 40px;
			top: 407px;
			.top{
				margin-right: 150px;
				white-space:nowrap;
			}
		}

		.infobox4 {
			right: 58px;
			top: 407px;
			.top{
				margin-left: 145px;
				white-space:nowrap;
			}
			.bottom {
				padding: 0;
			}
		}

		.infobox5 {
			width: 415px;
			left: 136px;
			top: 693px;
			.top{
				margin-right: 210px;
				white-space:nowrap;
			}
		}

		.infobox6 {
			width: 410px;
			right: 122px;
			top: 693px;
			.top{
				margin-left: 217px;
				white-space:nowrap;
			}
			.bottom {
				padding: 0;
			}
		}

		@keyframes rotate {
			0% {
				-webkit-transform: rotate(0deg);
			}

			25% {
				-webkit-transform: rotate(90deg);
			}

			50% {
				-webkit-transform: rotate(180deg);
			}

			75% {
				-webkit-transform: rotate(270deg);
			}

			100% {
				-webkit-transform: rotate(360deg);
			}
		}

		.img-rotate-box{
			// animation: rotate 3s linear infinite;
			padding-top: 110px;
		}
		.uuid{
			font-size: 30px;
			font-family: none;
		}
	}
</style>