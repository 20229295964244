<template>
	<div class="vehicles vehiclesPage">
		<div class="exposition-right-header">
			<MyHeader />
		</div>
		<div class="page-left flex justify-content-between">
			<div class="flex flex-direction page-left-demo">
				<div class="page-part mt-10" style="height: 430px;">
					<MyTitle title="光伏信息" />
					<guangfuxinxi></guangfuxinxi>
				</div>
				<div class="page-part mt-10">
					<MyTitle title="发电功率" type="2" />
					<fadiangonglv></fadiangonglv>
				</div>
				<div class="page-part mt-10">
					<MyTitle title="发电趋势" type="2" />
					<fadianqushi></fadianqushi>
				</div>
			</div>
			<div class="flex flex-direction page-left-demo">
				<div class="page-part mt-10" style="height: 430px;">
					<MyTitle title="储能信息" />
					<chunengxinxi></chunengxinxi>
				</div>
				<div class="page-part mt-10" style="height: 500px;">
					<chunengxinxi1></chunengxinxi1>
				</div>
				<div class="page-part mt-10">
					<MyTitle title="充放电趋势" type="2" />
					<chongfangliangqushi></chongfangliangqushi>
				</div>
			</div>
		</div>
		<div class="page-center-box flex-direction">
			<div class="flex1">
				<duonenghubu></duonenghubu>
			</div>
			<div class="page-center-bottom">
				<tanjianpaiqushi></tanjianpaiqushi>
			</div>
		</div>
		<div class="page-right"> 
			<div class="page-right-main">
				<MyTitle title="充换电信息" />
				<div class="page-right-main-top">
					<chonghuandianxinxi></chonghuandianxinxi>
				</div>
				<div class="page-right-main-center flex">
					<div class="page-right-main-center-cell flex1">
						<MyTitle title="总表电量及电价" type="2" />
						<zongdianliangjidianjia></zongdianliangjidianjia>
					</div>
					<div class="page-right-main-center-cell flex1">
						<MyTitle title="充换电订单" type="2" />
						<chonghuandiandingdan></chonghuandiandingdan>
					</div>
				</div>
				<div class="page-right-main-bottom">
					<MyTitle title="综合收益" />
					<div class="mt-50"></div>
					<MyTitle title="单站收益统计" type="2" />
					<leijishouyi></leijishouyi>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	import MyTitle from "@/views/components/MyTitle.vue"
	import MyHeader from "@/views/exposition/components/expositionHeader/expositionHeader.vue"
	import guangfuxinxi from '@/views/components/exposition/guangfuxinxi.vue'
	import chunengxinxi from '@/views/components/exposition/chunengxinxi.vue'
	import chunengxinxi1 from '@/views/components/exposition/chunengxinxi1.vue'
	import fadiangonglv from '@/views/components/exposition/fadiangonglv.vue'
	import fadianqushi from '@/views/components/exposition/fadianqushi.vue'
	import chongfangliangqushi from '@/views/components/exposition/chongfangliangqushi.vue'
	import chonghuandianxinxi from '@/views/components/exposition/chonghuandianxinxi.vue'
	import zongdianliangjidianjia from '@/views/components/exposition/zongdianliangjidianjia.vue'
	import chonghuandiandingdan from '@/views/components/exposition/chonghuandiandingdan.vue'
	import leijishouyi from '@/views/components/exposition/leijishouyi.vue'
	import duonenghubu from '@/views/components/exposition/duonenghubu.vue'
	import tanjianpaiqushi from '@/views/components/exposition/tanjianpaiqushi.vue'
	export default {
		components: {
			MyTitle,
			MyHeader,
			guangfuxinxi,
			chunengxinxi,
			chunengxinxi1,
			fadiangonglv,
			fadianqushi,
			chongfangliangqushi,
			chonghuandianxinxi,
			zongdianliangjidianjia,
			chonghuandiandingdan,
			leijishouyi,
			duonenghubu,
			tanjianpaiqushi
		},
		beforeCreate() {
			if(this.$route.query.fromScreen) {
				sessionStorage.setItem('fromScreen', this.$route.query.fromScreen)
			}
		}
	};
</script>

<style lang="scss" scoped>
	@import "@/assets/scss/comm.scss";
	$HeaderHeight: 188px;
	
	.vehicles {
		padding: 0 50px;
		color: #fff;
		background: url('~@/assets/images/exposition/dummyright-Bg.png') no-repeat #0C3131;
		background-size: 101% 101%;
		// background-position: -20px -20px;
		// background-color: yellow;
		.left-p1 {
			width: 455px;
		}
	}

	.vehiclesPage {
		// display: flex;
		position: relative;
		width: 100%;
		height: 100%;
		display: flex;
		background-size: 100% 100%;
		.exposition-right-header{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 190px;
		}

		// padding: 0 65px;
		// box-sizing: border-box;
		.page-left,
		.page-right,
		.page-center {
			// position: absolute;
			margin-top: $HeaderHeight;
			height: calc(100% - #{$HeaderHeight});
			// z-index: 10;
		}

		.page-left,
		.page-right {
			// background: rgba(0, 0, 0, 0.5);
			z-index: 1;

			.page-part {
				width: 100%;
				height: 520px;
				// background: orange;
			}
			.page-right-main{
				width: 1150px;
				.page-right-main-top{
					height: 165px;
				}
				.page-right-main-center{
					height: 450px;
					.flex1{
						flex: 1;
					}
				}
				.page-right-main-bottom{
					height: 630px;
					margin-top: 50px;
				}
			}
		}

		.page-left {
			width: 1180px;

			// position: absolute;
			// left: 0px;
			// background-color: red;
			.page-left-demo {
				width: 48.5%;
				height: 100%;
				// background: orange;
			}
		}

		.page-right {
			// position: absolute;
			// right: 0px;
			// background-color: skyblue;
		}

		.page-center-box {
			display: flex;
			box-sizing: border-box;
			flex: 1;
			padding-top: 100px;
			position: relative;
			.flex1{
				flex: 1;
			}
			.page-center-bottom {
				position: absolute;
				height: 450px;
				width: 100%;
				left: 0;
				bottom: 0;
			}
		}
	}
</style>