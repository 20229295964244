<template>
	<div class="tpjqu-com">
		<!-- <divBox1 :title="'碳减排趋势'"></divBox1> -->
		<div class="main-box-diangganggaikuang1">
			<div v-for="(item,i ) in target" :key="'target' + i" class="main-box-cell">
				<img class="img" :src="item.icon" alt="">
				<div class="main-box-right">
					<div class="top DS-Digital-Bold">
						<!-- {{ Number( item.number ).toFixed(0) }} -->
						<animate-number
							from="0"
							:to="Number( item.number ).toFixed(0)"
							:key="Number( item.number ).toFixed(0)"
							duration="2000"
						></animate-number>	
					</div>

					<div class="bottom flex align-center">
						<div class="left  flex1">
							{{ nowType() + item.label }} 
						</div>
						<div class="right" style="display: flex;flex-direction: column;align-items: flex-end;">
							<span
								style="font-size: 28px;">{{ nowType() == '日' ? '较昨日' : (nowType() == '月' ? '较上月' : '较去年' ) }}</span>
							<span class="DS-Digital-Bold" style="font-size: 34px; color: #fed15d;">
								<img class="down-img" v-if="item.float > 0" src="~@/assets/images/exposition/icon-up.png" alt="" />
								<img class="down-img" v-else src="~@/assets/images/exposition/icon-down.png" alt="" />
								{{ Number(item.float).toFixed(0) }}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		dataInterface
	} from '@/api/dataInterfaceApi';
	export default {
		components: {},
		data() {
			let mIcon = require('@/assets/images/heatmap/round.png')
			return {
				carbonData: {},
				target: [{
						icon: mIcon,
						label: '碳减排量(kg)',
						number: '',
						float: ''
					},
					{
						icon: mIcon,
						label: '节约标准煤炭量(kg)',
						number: '',
						float: ''
					}
				]
			}
		},
		destroyed() {
			// this.$bus.$off('onfangdiangonglv')
		},
		mounted() {
			this.$bus.$on('onfangdiangonglv', () => {
				this.getBattery24hours()
			})
			this.getBattery24hours()
		},
		methods: {
			nowType() {
				return localStorage.getItem('typeTime') || '日'
			},
			//获取24小时电次数分布
			getBattery24hours() {
				const now = new Date();
				const year = now.getFullYear();
				const month = (now.getMonth() + 1).toString().padStart(2, "0");
				const day = now.getDate().toString().padStart(2, "0");
				const timer = `${year}-${month}-${day}`
				let params = {
					"enCode": "snec-left-jiaozuoritanjianpai",
					"@harbour_id": localStorage.getItem("harbour_id") || '76df7579ccc24d2693f6488c38a3a0b0',
					"@dim_date": localStorage.getItem("changeDataNuber") || timer,
				}
				dataInterface.dataFaceApi(params).then((res) => {
					console.log(res, '碳减排数据表')
					let data = res[0] || {}
					this.target[0].number = data.carbon_emission
					this.target[1].number = data.coal_saving
					this.target[0].float = data.compare_carbon_emission
					this.target[1].float = data.compare_coal_saving
				}).catch((err) => {
					console.log(err);
				});
			}
		},
	}
</script>

<style lang="scss" scope>
	.tpjqu-com {
		margin-top: 30px;
		.main-box-diangganggaikuang1 {
			box-sizing: border-box;
			padding: 0 60px;
			height: 400px;
			display: flex;
			flex-wrap: wrap;
			z-index: 0;
			justify-content: space-between;

			@keyframes breath_light {
				0% {
					filter: brightness(100%);
				}

				50% {
					filter: brightness(180%);
				}

				100% {
					filter: brightness(100%);
				}
			}

			.main-box-cell {
				font-size: 38px;
				margin-bottom: 6px;
				margin-top: 20px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 620px;
				height: 400px;
				padding: 0;
				// padding-left: 140px;
				position: relative;
				.img {
					width: 210px;
					height: 210px;
					position: absolute;
					left: -10px;
					top: 80px;
					z-index: 1;
					opacity: 0.7;
				}
				.down-img{
					width: 40px;
					height: 40px;
				}

				.main-box-right {
					padding-left: 4px;
					flex: 1;
					font-size: 35px;
					position: absolute;
					top: 100px;
					left: 120px;
					z-index: 2;
					.top {
						font-size: 80px;
						height: 100px;
						line-height: 80px;
						white-space: nowrap;
						@include ffamily-HT;
						color: #02b9bd;;
					}

					.bottom {
						height: 100px;
						line-height: 40px;
						.left {
							color: #fff;
						}
						.right{
							margin-top: -55px;
							margin-left: 25px;
						}
					}
				}
			}

		}
	}
</style>