<template>
  <div>
    <div class="main-box-chunengxinxibottom">
      <div class="main-box-left">
        <div class="top">
          <div class="img" :class="{ beijing: channel == 'beijing' }">
            <div class="number DS-Digital-Bold">
              <!-- {{ storedEnergy.residue_elect || 0 }}% -->
              <animate-number
                from="0"
                class="top DS-Digital-Bold"
                :to="storedEnergy.residue_elect || 0"
                :key="storedEnergy.residue_elect || 0"
                duration="2000"
              ></animate-number
              >%
            </div>
            <img
              src="~@/assets/images/exposition/chucunshengyudianliang.png"
              alt=""
            />
          </div>
        </div>
        <div class="bottom">储能剩余电量</div>
      </div>
    </div>
  </div>
</template>

<script>
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  components: {},
  data() {
    return {
      storedEnergy: {},
      now: "日",
      channel: localStorage.getItem("newBigScreen::channel"),
    };
  },

  destroyed() {
    // this.$bus.$off('onfangdiangonglv')
  },
  mounted() {
    this.$bus.$on("onfangdiangonglv", () => {
      this.getstoredEnergy();
    });
    this.getstoredEnergy();
  },
  methods: {
    getstoredEnergy() {
      if (!localStorage.getItem("typeTime")) {
        console.log(localStorage.getItem("typeTime"), "111");
        this.now = "日";
      } else {
        console.log(localStorage.getItem("typeTime"), "222");
        this.now = localStorage.getItem("typeTime");
      }
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, "0");
      const day = now.getDate().toString().padStart(2, "0");
      const timer = `${year}-${month}-${day}`;

      let params = {
        enCode: "snec-left-chunengxinxi",
        "@dim_date": localStorage.getItem("changeDataNuber") || timer,
        "@harbour_id":
          localStorage.getItem("harbour_id") ||
          "76df7579ccc24d2693f6488c38a3a0b0",
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          console.log(res, "储能信息");
          this.storedEnergy = res[0] || {};
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scope>
@import "@/assets/scss/comm.scss";
.main-box-chunengxinxibottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 18px;
  width: 100%;
  .main-box-left {
    width: 540px;

    .img {
      width: 300px;
      height: 300px;
      position: relative;
      margin-top: 0px;
      margin-left: 120px;
      &.beijing {
        height: 120px;
      }

      .number {
        font-size: 66px;
        position: absolute;
        width: 140px;
        height: 80px;
        left: 50%;
        top: 50%;
        margin-left: -70px;
        margin-top: -40px;
        text-align: center;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .bottom {
      text-align: center;
      height: 60px;
      width: 100%;
      line-height: 80px;
      font-size: 46px;
      @include ffamily-HT;
    }
  }
}
</style>