<template>
	<div>
		<!-- <divBox2 :title="'充换电订单'"></divBox2> -->
		<div style="width:540px;height:360px;margin-top: 20px;">
			<MyEcharts :visible="showcdata" :id="'chonghuandiandingdan'" :options="cdata" />
		</div>


	</div>
</template>

<script>
	import MyEcharts from '@/views/components/charts/echarts.vue'
	import * as echarts from 'echarts'
	import {
		dataInterface
	} from '@/api/dataInterfaceApi';
	export default {
		components: {
			MyEcharts
		},
		data() {
			return {
				chonghuandiandingdaniData: {},
				showcdata: false,
				// title:"车辆新增及活跃趋势",
				cdata: {
					title:{
						text: '单'
					},
					color: ['#FFCE53', '#11BFE1', '#84F3F5'],
					backgroundColor: 'rgba(0, 0, 0, 0.1)',
					tooltip: {
						trigger: 'axis',
						show: true,
						axisPointer: {
							type: 'cross',
							label: {
								backgroundColor: '#6a7985',
							}
						}
					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: []
					},
					yAxis: {
						// name: '单  ',
						type: 'value',
						splitLine: false
					},
					series: [{
							name: '乘用车换电',
							type: 'line',
							smooth: true,
							data: [],
							tooltip: {
								valueFormatter: function(value) {
									return value + ' 单';
								}
							},
							lineStyle: {
								color: '#f9ce60'
							},
							areaStyle: {
								opacity: 0.8,
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 0,
										color: '#f9ce60'
									},
									{
										offset: 1,
										color: 'rgba(249, 206, 96,0)'
									}
								])
							},
						},
						{
							name: '商用车换电',
							type: 'line',
							smooth: true,
							data: [],
							tooltip: {
								valueFormatter: function(value) {
									return value + ' 单';
								}
							},
							lineStyle: {
								color: '#1181ea'
							},
							areaStyle: {
								opacity: 0.8,
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 0,
										color: '#1181ea'
									},
									{
										offset: 1,
										color: 'rgba(17, 129, 234,0)'
									}
								])
							},
						},
						{
							name: '充电',
							type: 'line',
							smooth: true,
							data: [],
							tooltip: {
								valueFormatter: function(value) {
									return value + ' 单';
								}
							},
							lineStyle: {
								color: '#33cb82'
							},
							areaStyle: {
								opacity: 0.8,
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 0,
										color: '#33cb82'
									},
									{
										offset: 1,
										color: '#061c12'
									}
								])
							},
						}
					]
				}
			};
		},
		destroyed() {
			// this.$bus.$off('onfangdiangonglv')
		},
		mounted() {
			this.$bus.$on('onfangdiangonglv', () => {
				this.getChonghuandiandingdan()
			})
			this.getChonghuandiandingdan()
		},
		methods: {
			//获取充换电信息
			getChonghuandiandingdan() {
				const now = new Date();
				const year = now.getFullYear();
				const month = (now.getMonth() + 1).toString().padStart(2, "0");
				const day = now.getDate().toString().padStart(2, "0");
				const timer = `${year}-${month}-${day}`
				console.log(localStorage.getItem("changeDataNuber"))
				let params = {
					"enCode": "snec-right-chonghuandiandingdan",
					"@dim_date": localStorage.getItem("changeDataNuber") || timer,
					"@harbour_id": localStorage.getItem("harbour_id") || '76df7579ccc24d2693f6488c38a3a0b0'
				}
				this.showcdata = false
				dataInterface.dataFaceApi(params).then((res) => {
					if (res) {
						let data = []
						let data1 = []
						let data2 = []
						let xAxis = []
						res.forEach(item => {
							xAxis.push(item.dim_date)
							data.push(item.cheng_exchange_num)
							data1.push(item.shang_exchange_num)
							data2.push(item.charge_num)
						})
						this.cdata.xAxis.data = xAxis
						this.cdata.series[0].data = data
						this.cdata.series[1].data = data1
						this.cdata.series[2].data = data2
						this.showcdata = true
					}
				}).catch((err) => {
					console.log(err);
				});
			}
		}

	}
</script>

<style lang="scss" scope>
	.main-box-diangganggaikuang {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.main-box-cell {
			margin-bottom: 6px;
			display: flex;
			width: 200px;
			height: 100px;
			padding: 0 10px;

			.main-box-left {
				img {
					width: 80px;
					height: 80px;
				}
			}

			.main-box-right {
				padding-left: 15px;

				.top {
					height: 50px;
					line-height: 70px;
				}

				.bottom {
					height: 50px;
					font-size: 22px;
					line-height: 30px;
				}
			}
		}
	}
</style>