<template>
		<div style="width:540px;height:360px;margin-top: 20px;">
			<MyEcharts :visible="showcdata" :id="'options24hour33eswarn1122'" :options="cdata" />
		</div>
</template>

<script>
	import MyEcharts from '@/views/components/charts/echarts.vue'
	import * as echarts from 'echarts'
	import {
		dataInterface
	} from '@/api/dataInterfaceApi';
	export default {
		components: {
			MyEcharts
		},
		data() {
			return {
				showcdata: false,
				// title:"车辆新增及活跃趋势"
				cdata: {
					title:{
						text: 'kWh'
					},
					tooltip: {
						trigger: 'axis',
						show: true,
						axisPointer: {
							type: 'cross',
							label: {
								backgroundColor: '#6a7985',
							}
						}
					},
					color: ['#FFCE51', '#82F2F4'],
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: []
					},
					yAxis: {
						// name: 'kWh  ',
						type: 'value',
					},
					series: [{
						name: '发电功率',
						type: 'line',
						smooth: true,
						data: [],
						tooltip: {
							valueFormatter: function(value) {
								return value + ' kWh';
							}
						},
						itemStyle: {
							color: '#FFCE51'
						},
						lineStyle: {
							color: '#FFCE51'
						},
						areaStyle: {
							opacity: 0.8,
							color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
									offset: 0,
									color: '#FFCE51'
								},
								{
									offset: 1,
									color: 'rgba(255, 206, 81,0)'
								}
							])
						},
					}, {
						name: '周平均功率',
						type: 'line',
						smooth: true,
						data: [],
						tooltip: {
							valueFormatter: function(value) {
								return value + ' kWh';
							}
						},
						itemStyle: {
							color: '#82F2F4'
						},
						lineStyle: {
							color: '#82F2F4'
						},
						areaStyle: {
							opacity: 0.8,
							color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
									offset: 0,
									color: '#82F2F4'
								},
								{
									offset: 1,
									color: 'rgba(130, 242, 244,0)'
								}
							])
						},
					}]
				}
			};
		},
		mounted() {
			this.$bus.$on('onfangdiangonglv', () => {
				this.getBattery24hours()
			})
			this.getBattery24hours()
		},
		destroyed() {
			// this.$bus.$off('onfangdiangonglv')
		},
		methods: {
			//获取24小时电次数分布
			getBattery24hours() {
				const now = new Date();
				const year = now.getFullYear();
				const month = (now.getMonth() + 1).toString().padStart(2, "0");
				const day = now.getDate().toString().padStart(2, "0");
				const timer = `${year}-${month}-${day}`
				let params = {
					"enCode": "snec-left-fadiangonglv",
					"@harbour_id": localStorage.getItem("harbour_id") || '76df7579ccc24d2693f6488c38a3a0b0',
					"@dim_date": localStorage.getItem("changeDataNuber") || timer,
				}
				this.showcdata = false
				dataInterface.dataFaceApi(params).then((res) => {
					console.log(res, '发电功率')
					if (res) {
						let data = []
						let data2 = []
						let xAxis = []
						res.forEach(item => {
							xAxis.push(item.dim_date)
							data.push(item.output_generated)
							data2.push(item.weekly_average_power)
						})
						this.cdata.xAxis.data = xAxis
						this.cdata.series[0].data = data
						this.cdata.series[1].data = data2
						this.showcdata = true
					}
				}).catch((err) => {
					console.log(err);
				});
			}
		}
	}
</script>

<style lang="scss" scope>
	.main-box-diangganggaikuang {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.main-box-cell {
			margin-bottom: 6px;
			display: flex;
			width: 200px;
			height: 100px;
			padding: 0 10px;

			.main-box-left {
				img {
					width: 80px;
					height: 80px;
				}
			}

			.main-box-right {
				padding-left: 15px;

				.top {
					height: 50px;
					line-height: 70px;
				}

				.bottom {
					height: 50px;
					font-size: 22px;
					line-height: 30px;
				}
			}
		}
	}
</style>