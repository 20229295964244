<template>
	<div>
		<!-- <divBox1 :title="'充换电信息'"></divBox1> -->
		<div class="main-box-diangganggaikuang122">
			<div
				v-for="(item, i) in target"
				:key="'target' + i"
				class="main-box-cell flex align-center">
				<img class="img" :src="item.icon" alt="">
				<div class="main-box-right">
					<div class="top">{{ now + item.label }}</div>
					<div class="bottom DS-Digital-Bold">
						<!-- {{ item.number | Mathround }} -->
						<animate-number
							from="0"
							:to="item.number | Mathround"
							:key="item.number | Mathround"
							duration="2000"
						></animate-number>	
					<span class="unit" style="font-family:none ;">{{item.unit}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  components: {},
  data() {
    let mIcon5 = require("@/assets/images/exposition/imgp5.png");
    let mIcon6 = require("@/assets/images/exposition/imgp6.png");
    let mIcon7 = require("@/assets/images/exposition/imgp7.png");
    let mIcon8 = require("@/assets/images/exposition/imgp8.png");
    return {
      dataInfo: {},
      now: "日",
      target: [
        {
          icon: mIcon5,
          label: "充换电次数",
          number: "",
          unit: "次",
        },
        {
          icon: mIcon6,
          label: "充换电电量",
          number: "",
          unit: "kWh",
        },
        {
          icon: mIcon7,
          label: "运营收入",
          number: "",
          unit: "元",
        },
        {
          icon: mIcon8,
          label: "充电成本",
          number: "",
          unit: "元",
        },
      ],
    };
  },
  filters: {
    Mathround(value) {
      if (value) {
        return Math.round(value);
      } else {
        return 0;
      }
    },
  },
  methods: {
    //获取充换电信息
    getChonghuandianxinxi() {
      if (!localStorage.getItem("typeTime")) {
        console.log(localStorage.getItem("typeTime"), "111");
        this.now = "日";
      } else {
        console.log(localStorage.getItem("typeTime"), "222");
        this.now = localStorage.getItem("typeTime");
      }
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, "0");
      const day = now.getDate().toString().padStart(2, "0");
      const timer = `${year}-${month}-${day}`;
      console.log(localStorage.getItem("changeDataNuber"));
      let params = {
        enCode: "snec-right-chonghuandianxinxi",
        "@dim_date": localStorage.getItem("changeDataNuber") || timer,
        "@harbour_id":
          localStorage.getItem("harbour_id") ||
          "76df7579ccc24d2693f6488c38a3a0b0",
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          if (!res || !res.length) return;
          let data = res[0] || {};
          this.target[0].number = data.charge_or_exchange_num;
          this.target[1].number = data.elect;
          this.target[2].number = data.income;
          this.target[3].number = data.cost;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  destroyed() {
    // this.$bus.$off('onfangdiangonglv')
  },
  mounted() {
    this.$bus.$on("onfangdiangonglv", () => {
      this.getChonghuandianxinxi();
    });
    this.getChonghuandianxinxi();
  },
};
</script>

<style lang="scss" scope>
@font-face {
  font-family: "DS-Digital-Bold";
  src: url("~@/assets/font/DS-Digital-Bold.ttf");
}
.DS-Digital-Bold {
  font-family: "DS-Digital-Bold";
}
.main-box-diangganggaikuang122 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 45px;
  @keyframes breath_light {
    0% {
      filter: brightness(100%);
    }

    50% {
      filter: brightness(180%);
    }

    100% {
      filter: brightness(100%);
    }
  }

  .main-box-cell {
    margin-bottom: 6px;
    height: 100px;
    width: 265px;

    .img {
      animation: breath_light 3s ease infinite;
      margin-top: 25px;
      width: 100px;
      height: 70px;
    }

    .main-box-right {
      padding-left: 15px;

      .top {
        height: 50px;
        line-height: 60px;
        font-size: 28px;
        white-space: nowrap;
      }

      .bottom {
        height: 70px;
        font-size: 41px;
        line-height: 62px;
        color: #02b9bd;
        .unit {
          font-size: 24px;
        }
      }
    }
  }
}
</style>